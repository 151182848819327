











import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import RowActionModel from '@/components/list/RowActionModel'

@Component
export default class RowAction extends Vue {
  @Prop({ type: RowActionModel, required: true })
  private action: RowActionModel

  @Prop({ required: true })
  private item: any

  private isLoading = false

  private async clickAction() {
    if (this.isLoading) {
      return
    }

    if (this.action.requiresConfirmation === true) {
      this.$emit('confirmationDialog', { action: this.action, item: this.item })
    } else {
      this.isLoading = true
      await this.action.click(this.$parent.$parent.$parent.$parent, this.item)
      this.isLoading = false
    }
  }
}
