export default class RowActionModel {
  public id: string
  public label: string
  public absolute: boolean
  public popup: boolean
  public route: any
  public visibility: any
  public click: any
  public requiresConfirmation: boolean
  public confirmationTitle: string
  public confirmationText: string

  constructor(data) {
    this.id = data.id || ''
    this.label = data.label || ''
    this.route = data.route || null
    this.click = data.click || null
    this.absolute = data.absolute === undefined ? false : data.absolute
    this.visibility = data.visibility || null
    this.popup = data.popup === undefined ? false : data.popup
    this.requiresConfirmation = data.requiresConfirmation === undefined ? null : data.requiresConfirmation
    this.confirmationTitle = data.confirmationTitle || null
    this.confirmationText = data.confirmationText || null
  }
}
